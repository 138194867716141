<template>
    <router-link class="news_unit" :to="`/news/view/${news.id}`">
        <img class="main" :src="news.main_img" :alt="news.name" />
        <div class="date">{{ news.date }}</div>
        <div class="name mb-3">{{ news.name }}</div>
        <span class="news_more button">
            {{ $store.state.language.news.more }}
            <i class="fa fa-arrow-right"></i>
        </span>
    </router-link>
</template>
<style lang="scss">
.news_unit {
    display: block;
    text-decoration: none;
    transition: all 0.2s;
    box-shadow: none;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 1.5em;
    position: relative;

    .main {
        width: 100%;
        margin: 0 auto;
    }
    .date {
        color: #f07e00;
        font-weight: bold;
        font-size: 1em;
    }
    .name {
        color: #999999;
        font-size: 1.3em;
        line-height: 30px;
        height: 60px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    &:hover {
        box-shadow: 3px 3px 5px 3px #ccc;
        top: -1px;
        left: -1px;
        .news_more i {
            position: relative;
            animation-name: more;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
        }
    }
}
@keyframes more {
    from {
        left: 0;
    }

    to {
        left: 5px;
    }
}
</style>
<script>
export default {
    name: "news_unit",
    props: ["news"]
};
</script>
