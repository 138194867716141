<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
                <router-link
                    class="tab_btn"
                    :class="nowtab == '0' ? 'active' : ''"
                    :to="`#0`"
                    >{{ $store.state.language.news.all }}
                </router-link>
                <router-link
                    class="tab_btn"
                    v-for="nc in $store.state.data.news_catg"
                    :key="`catg_${nc.id}`"
                    :class="nowtab == nc.id ? 'active' : ''"
                    :to="`#${nc.id}`"
                    >{{ nc.name }}
                </router-link>
            </div>
        </div>
        <div style="min-height: 200px;">
            <transition-group name="slide-fade">
                <div
                    class="row mb-5"
                    v-for="(nlist, nk) in newsList"
                    :key="`list_${nk}`"
                    v-show="nowtab == nk"
                >
                    <div
                        class="col-md-6 col-lg-4"
                        v-for="(n, nid) in nlist"
                        :key="`news_${nid}`"
                    >
                        <newsUnit :news="n"></newsUnit>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>
<script>
import newsUnit from "./news_unit.vue";
export default {
    components: { newsUnit },
    data() {
        return {
            nowtab: "0"
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.hash"() {
            this.init();
        }
    },
    computed: {
        newsList() {
            let output = {};
            output["0"] = [];
            if (this.$store.state.data.news) {
                this.$store.state.data.news.forEach(n => {
                    let c = n.id_news_catg;
                    // 區分類別
                    if (!output[c]) {
                        output[c] = [];
                    }
                    output["0"].push(n);
                    output[c].push(n);
                });
            }
            return output;
        }
    },
    methods: {
        init() {
            if (this.$route.hash) {
                this.nowtab = this.$route.hash.substr(1);
            }
        }
    }
};
</script>
